<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>{{ formTitle }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <ValidationProvider name="Id">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.id" label="Id" :error-messages="errors" disabled />
                    </template>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider name="Name" rules="required|max:128">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.name" label="Name*" :error-messages="errors" />
                    </template>
                  </ValidationProvider>
                </v-col>
                <v-col v-for="(assessment, index) in item.assessmentsList || []" :key="`assessment-${index}`" cols="12">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <ValidationProvider name="Session Types">
                        <template v-slot="{ errors }">
                          <v-autocomplete
                            v-model="assessment.sessionTypes"
                            label="Session Types"
                            :items="filteredSessionTypeOptions"
                            item-text="name"
                            item-value="id"
                            return-object
                            multiple
                            clearable
                            chips
                            :error-messages="errors"
                            @change="
                              filterLevelsAndAssessments(index)
                              preventMixedSessionTypes(index)
                            "
                          />
                        </template>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <ValidationProvider name="Level">
                        <template v-slot="{ errors }">
                          <v-select
                            v-model="assessment.level"
                            label="Level"
                            :items="filteredLevels[index]"
                            :error-messages="errors"
                            clearable
                            @change="filterAssessments(index)"
                          />
                        </template>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider name="Assessments" rules="required">
                        <template v-slot="{ errors }">
                          <v-autocomplete
                            v-model="assessment.selectedAssessments"
                            label="Add Assessments"
                            :items="filteredAssessments[index]"
                            item-text="name"
                            item-value="id"
                            return-object
                            multiple
                            clearable
                            chips
                            :error-messages="errors"
                            @change="preventMixedAssessments(index)"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                close
                                small
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                @click:close="removeSelectedAssessment(index, data.item)"
                              >
                                {{ data.item.titleOfSession }} - {{ data.item.sessionType }} - {{ data.item.level }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.titleOfSession }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-chip small>
                                    {{ data.item.sessionType }}
                                  </v-chip>
                                  <v-chip small>
                                    {{ data.item.level }}
                                  </v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </template>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="onCancel"> Cancel </v-btn>
        <v-btn text :loading="isLoading" @click="onSave"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useGolfAssessments from '@/composables/api/assessments/golf'
import useFitnessAssessments from '@/composables/api/assessments/fitness'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: Boolean,
    item: Object,
    formTitle: String,
    isLoading: Boolean,
    icons: Object,
  },
  data() {
    return {
      sessionTypeOptions: [],
      allAssessments: [],
      filteredSessionTypeOptions: [],
      filteredLevels: [],
      filteredAssessments: [],
      overallAssessmentError: '',
    }
  },
  async created() {
    const { fetchGolfAssessments, golfAssessments } = useGolfAssessments()
    const { fetchFitnessAssessments, fitnessAssessments } = useFitnessAssessments()

    await fetchGolfAssessments()
    await fetchFitnessAssessments()

    this.golfAssessments = golfAssessments.value
    this.fitnessAssessments = fitnessAssessments.value

    this.golfSessionTypeOptions = [
      ...new Set(golfAssessments.value.map(a => ({ id: a.sessionType, name: a.sessionType }))),
    ]

    this.fitnessSessionTypeOptions = [
      ...new Set(fitnessAssessments.value.map(a => ({ id: a.sessionType, name: a.sessionType }))),
    ]

    this.sessionTypeOptions = [...this.golfSessionTypeOptions, ...this.fitnessSessionTypeOptions]

    this.allAssessments = [...this.golfAssessments, ...this.fitnessAssessments]
    this.filteredSessionTypeOptions = [...this.sessionTypeOptions]
  },
  methods: {
    filterLevelsAndAssessments(index) {
      const selectedSessionTypes = this.item.assessmentsList[index].sessionTypes.map(st => st.id)

      const assessments = this.allAssessments.filter(a => selectedSessionTypes.includes(a.sessionType))

      const filteredLevels = [...new Set(assessments.map(a => a.level))]
      this.$set(this.filteredLevels, index, filteredLevels)

      this.filterAssessments(index)
    },
    filterAssessments(index) {
      const selectedSessionTypes = this.item.assessmentsList[index].sessionTypes.map(st => st.id)
      const selectedLevel = this.item.assessmentsList[index].level

      const assessments = this.allAssessments.filter(
        a => selectedSessionTypes.includes(a.sessionType) && (!selectedLevel || a.level === selectedLevel),
      )

      this.$set(this.filteredAssessments, index, assessments)
    },
    preventMixedSessionTypes(index) {
      const { sessionTypes } = this.item.assessmentsList[index]

      if (sessionTypes.length > 0) {
        const isGolf = this.golfSessionTypeOptions.some(st => sessionTypes.includes(st))
        const isFitness = this.fitnessSessionTypeOptions.some(st => sessionTypes.includes(st))

        if (isGolf) {
          this.filteredSessionTypeOptions = this.golfSessionTypeOptions
        } else if (isFitness) {
          this.filteredSessionTypeOptions = this.fitnessSessionTypeOptions
        }
      } else {
        this.filteredSessionTypeOptions = [...this.sessionTypeOptions]
      }
    },
    preventMixedAssessments(index) {
      const assessment = this.item.assessmentsList[index]
      const { selectedAssessments } = assessment

      if (selectedAssessments.length > 0) {
        const isGolf = selectedAssessments.every(sa => sa.sessionType === 'Golf')
        const isFitness = selectedAssessments.every(sa => sa.sessionType === 'Fitness')

        if (isGolf) {
          this.$set(
            this.filteredAssessments,
            index,
            this.filteredAssessments[index].filter(a => a.sessionType === 'Golf'),
          )
        } else if (isFitness) {
          this.$set(
            this.filteredAssessments,
            index,
            this.filteredAssessments[index].filter(a => a.sessionType === 'Fitness'),
          )
        }
      }
    },
    removeSelectedAssessment(index, item) {
      const assessment = this.item.assessmentsList[index]
      assessment.selectedAssessments = assessment.selectedAssessments.filter(a => a.id !== item.id)
      this.preventMixedAssessments(index)
    },
    async onSave() {
      this.overallAssessmentError = ''

      const isValid = await this.$refs.formObserver.validate()

      if (isValid) {
        if (this.item.assessmentsList.every(assessment => assessment.selectedAssessments.length > 0)) {
          const saveData = {
            name: this.item.name,
            assessmentsList: this.item.assessmentsList[0].selectedAssessments,
          }
          if (this.item.id !== '') {
            saveData.id = this.item.id
          }
          this.$emit('save', saveData)
        } else {
          this.overallAssessmentError = 'At least one assessment must be selected in each assessment block.'
        }
      }
    },
    onCancel() {
      this.$emit('cancel')
    },
  },
}
</script>
