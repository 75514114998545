import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useGroupAssessments() {
  const RESOURCE = '/assessment/group'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'NAME', value: 'name',
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]

  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankGroupAssessment = {
    id: '',
    name: '',
    assessmentsList: [
      {
        id: '',
        uniqueKey: '',
        sessionType: '',
        category: '',
        level: '',
        frequency: '',
        measurement: '',
        practiceType: '',
        goal: '',
        sortNo: '',
        exercisePriority: '',
        tips: [],
        scoringDescriptions: [],
        exercise: null,
        exerciseUniqueKey: '',
      },
    ],
  }

  const groupAssessments = ref([])
  const groupAssessment = ref(structuredClone(blankGroupAssessment))

  const isLoading = ref(false)

  const createGroupAssessment = async groupAssessmentItem => {
    isLoading.value = true

    await axios.post(RESOURCE, groupAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully created a new group assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new group assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteGroupAssessment = async groupAssessmentItem => {
    isLoading.value = true

    await axios.delete(`${RESOURCE}/delete/${groupAssessmentItem.id}`)
      .then(() => {
        showSnackbar('You have successfully deleted the group assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to delete the group assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const fetchGroupAssessments = async () => {
    isLoading.value = true

    await axios.get(RESOURCE)
      .then(response => {
        console.log(response.data)
        groupAssessments.value = response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch group assessments:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateGroupAssessment = async groupAssessmentItem => {
    isLoading.value = true

    await axios.put(RESOURCE, groupAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully updated the group assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the group assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankGroupAssessment,
    groupAssessments,
    groupAssessment,

    isLoading,

    createGroupAssessment,
    deleteGroupAssessment,
    fetchGroupAssessments,
    updateGroupAssessment,
  }
}
