<template>
  <div>
    <v-card>
      <v-card-title>
        Group Assessments Library
        <v-spacer />
        <v-btn class="mb-2" color="primary" dark @click="showEditDialog(blankGroupAssessment)">
          CREATE
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="dtSearchQuery"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="dtHeaders"
        :items="groupAssessments"
        :search="dtSearchQuery"
        :expanded.sync="dtExpanded"
        :loading="isLoading"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [15, 30, 45] }"
        single-expand
        show-expand
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            @click="
              showEditDialog({
                id: item.id,
                name: item.name,
                assessmentsList: [
                  {
                    id: '',
                    uniqueKey: '',
                    sessionType: '',
                    category: '',
                    level: '',
                    frequency: '',
                    measurement: '',
                    practiceType: '',
                    goal: '',
                    sortNo: '',
                    exercisePriority: '',
                    tips: [],
                    scoringDescriptions: [],
                    exercise: null,
                    exerciseUniqueKey: '',
                  },
                ],
              })
            "
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="showDeleteConfirmationDialog(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <group-assessment-details :item="item" />
          </td>
        </template>
      </v-data-table>

      <delete-dialog
        v-model="isDeleteDialogVisible"
        :item="groupAssessment"
        :is-loading="isLoading"
        @cancel="onDeleteCanceled"
        @delete="onDeleteConfirmed"
      />

      <group-assessment-edit-dialog
        v-if="isEditDialogVisible"
        v-model="isEditDialogVisible"
        :item="groupAssessment"
        :is-loading="isLoading"
        @cancel="onEditCanceled"
        @save="onEditSave"
      />
    </v-card>
  </div>
</template>

<script>
import {
  mdiCheck, mdiClose, mdiDeleteOutline, mdiPencilOutline,
} from '@mdi/js'
import { onMounted, ref } from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import GroupAssessmentDetails from '@/views/groupAssessments/partials/GroupAssessmentDetails.vue'
import GroupAssessmentEditDialog from '@/views/groupAssessments/partials/GroupAssessmentEditDialog.vue'
import useGroupAssessments from '@/composables/api/assessments/group'
import useUniqueKey from '@/composables/uniqueKey'

export default {
  components: {
    DeleteDialog,
    GroupAssessmentDetails,
    GroupAssessmentEditDialog,
  },

  setup() {
    const {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,
      blankGroupAssessment,
      groupAssessment,
      groupAssessments,
      isLoading,
      createGroupAssessment,
      deleteGroupAssessment,
      fetchGroupAssessments,
      updateGroupAssessment,
    } = useGroupAssessments()

    const { uniqueKey } = useUniqueKey()

    const isDeleteDialogVisible = ref(false)
    const isEditDialogVisible = ref(false)

    onMounted(async () => {
      await fetchGroupAssessments()
    })

    const showDeleteConfirmationDialog = groupAssessmentItem => {
      groupAssessment.value = groupAssessmentItem
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      groupAssessment.value = structuredClone(blankGroupAssessment)
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async groupAssessmentItem => {
      await deleteGroupAssessment(groupAssessmentItem)
      await fetchGroupAssessments()

      isDeleteDialogVisible.value = false
    }

    const showEditDialog = groupAssessmentItem => {
      const item = structuredClone(groupAssessmentItem)

      if (!item.uniqueKey) {
        item.uniqueKey = uniqueKey()
      }

      groupAssessment.value = item
      isEditDialogVisible.value = true
    }

    const onEditCanceled = () => {
      groupAssessment.value = structuredClone(blankGroupAssessment)
      isEditDialogVisible.value = false
    }

    const onEditSave = async groupAssessmentItem => {
      try {
        if (!groupAssessmentItem.id) {
          await createGroupAssessment(groupAssessmentItem)
          await fetchGroupAssessments()
        } else {
          await updateGroupAssessment(groupAssessmentItem)
          await fetchGroupAssessments()
        }

        isEditDialogVisible.value = false
      } catch (error) {
        console.error(error)
      }
    }

    return {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,

      blankGroupAssessment,
      groupAssessments,
      groupAssessment,

      isDeleteDialogVisible,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,

      isEditDialogVisible,
      showEditDialog,
      onEditCanceled,
      onEditSave,

      isLoading,

      icons: {
        mdiCheck,
        mdiClose,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
