var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Group Assessments Library "),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.showEditDialog(_vm.blankGroupAssessment)}}},[_vm._v(" CREATE ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","offset-md":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.dtSearchQuery),callback:function ($$v) {_vm.dtSearchQuery=$$v},expression:"dtSearchQuery"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.dtHeaders,"items":_vm.groupAssessments,"search":_vm.dtSearchQuery,"expanded":_vm.dtExpanded,"loading":_vm.isLoading,"items-per-page":30,"footer-props":{ 'items-per-page-options': [15, 30, 45] },"single-expand":"","show-expand":""},on:{"update:expanded":function($event){_vm.dtExpanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showEditDialog({
              id: item.id,
              name: item.name,
              assessmentsList: [
                {
                  id: '',
                  uniqueKey: '',
                  sessionType: '',
                  category: '',
                  level: '',
                  frequency: '',
                  measurement: '',
                  practiceType: '',
                  goal: '',
                  sortNo: '',
                  exercisePriority: '',
                  tips: [],
                  scoringDescriptions: [],
                  exercise: null,
                  exerciseUniqueKey: '',
                },
              ],
            })}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('v-icon',{on:{"click":function($event){return _vm.showDeleteConfirmationDialog(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('group-assessment-details',{attrs:{"item":item}})],1)]}}])}),_c('delete-dialog',{attrs:{"item":_vm.groupAssessment,"is-loading":_vm.isLoading},on:{"cancel":_vm.onDeleteCanceled,"delete":_vm.onDeleteConfirmed},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}}),(_vm.isEditDialogVisible)?_c('group-assessment-edit-dialog',{attrs:{"item":_vm.groupAssessment,"is-loading":_vm.isLoading},on:{"cancel":_vm.onEditCanceled,"save":_vm.onEditSave},model:{value:(_vm.isEditDialogVisible),callback:function ($$v) {_vm.isEditDialogVisible=$$v},expression:"isEditDialogVisible"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }